import gql from 'graphql-tag';
import { ImageFields } from '../common-fields.queries';

export const BasicPriceInfo = gql`
	fragment BasicPriceInfo on SearchProduct {
		priceInfo {
			current
			unitPrice {
				units
				price
			}
		}
	}
`;

export const DefaultSearchProductFields = gql`
	fragment DefaultSearchProductFields on SearchProduct {
		id
		familyId
		modelNumber
		title
		url
		squareFootageBased
		rating {
			reviewCount
			ratingValue
		}
		brandName
		isConfigurable
		parts {
			name
			url
		}
		isAppointmentOnlyBrand
		productPricingDisplay
		image {
			...ImageFields
		}
		...BasicPriceInfo
	}
	${BasicPriceInfo}
	${ImageFields}
`;
