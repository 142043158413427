import gql from 'graphql-tag';
import { ImageFields } from '../common-fields.queries';
import { DefaultSearchProductFields } from './search-product-fields.queries';

export const PriceInfoWithRange = gql`
	fragment PriceInfoWithRange on SearchProduct {
		priceInfo {
			current
			range {
				min
				max
			}
			unitPrice {
				units
				price
				range {
					min
					max
				}
			}
		}
	}
`;

export const SearchVariantFields = gql`
	fragment SearchVariantFields on SearchVariant {
		id
		name
		modelNumber
		swatchColor
		url
		isQuickShip
		estimatedDeliveryMessage
		hasFreeShipping
		priceInfo {
			current
			unitPrice {
				price
			}
		}
		image {
			...ImageFields
		}
		status
		inventoryCount
		isProductRestricted
		productPricingDisplay
	}
	${ImageFields}
`;

export const SearchProductDropFields = gql`
	fragment SearchProductDropFields on SearchProduct {
		...DefaultSearchProductFields
		...PriceInfoWithRange
		collection {
			name
			url
		}
		shippingInfo {
			isQuickShip
			message
		}
		attributes {
			name
			values
			id
		}
		variants {
			...SearchVariantFields
		}
	}
	${DefaultSearchProductFields}
	${SearchVariantFields}
	${PriceInfoWithRange}
`;
